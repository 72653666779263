import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiEndpoint } from '../../config';
import { GridLoader } from 'react-spinners';
import Error from '../Error';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';

// Images and icons
import BackgroundConfetti from '../../assets/banner/confetti.svg';
import TickBlue from '../../assets/icons/tick-blue.svg';
import TickRed from '../../assets/icons/tick-red.svg';
import WhatsApp from '../../assets/icons/whatsapp.svg';
import Facebook from '../../assets/icons/facebook.svg';
// import Instagram from '../../assets/icons/instagram.svg';
import Twitter from '../../assets/icons/x.svg';

// Styles
import styles from './SubmitPopUp.module.css';
import { ogLanguages, popupButtonLanguages, submitLanguages } from '../../helper/LanguageConstants';

const SubmitPopUp = ({
    closeSubmitPopUp,
    language,
    setPageType,
    setQuizAnswered,
    loaderColor,
}) => {

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [thankyouData, setThankyouData] = useState('');

    const shareUrl = window.location.href;

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/successfull-partcipating-modal?populate[0]=Social_Media&populate[1]=Social_Media.Image&populate[2]=OTT_Banner_Hindi&populate[3]=OTT_Banner_English`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setThankyouData(responseData?.data);
                    setLoading(false);
                    setError(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={styles.submitOverlay}
        >
            <div className={styles.popupContainer}>
                <div className={styles.innerContainer}>
                    {loading ? (
                        <div className={styles.contentLoader}>
                            <GridLoader
                                color={loaderColor}
                                loading={true}
                                size={20}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            />
                        </div>) : error ? (
                            <div className={styles.contentLoader}>
                                <Error language={language} />
                            </div>
                        ) : (
                        <React.Fragment>
                            <img src={BackgroundConfetti} alt='confetti' className={styles.background} />
                            <div className={styles.submitContent}>
                                <img src={language === 'hindi' ? TickRed : TickBlue} alt='tick-mark' className={styles.tickMark} />
                                <div className={styles.title}>
                                    <h3>{language === 'hindi' ?
                                        thankyouData?.attributes?.Content_Hindi
                                        : thankyouData?.attributes?.Content_English
                                    }
                                    </h3>
                                </div>

                                <div className={styles.share}>
                                    <span>{language === 'hindi' ?
                                        submitLanguages?.share?.hindiText
                                        : submitLanguages?.share?.defaultText
                                    }</span>
                                </div>
                                <div className={styles.socialContainer}>
                                    {/* {thankyouData?.attributes?.Social_Media.map((socialLink, index) => (
                                        <a
                                            key={index}
                                            href={socialLink?.Link}
                                            target='_blank'
                                            className={styles.socialLink}
                                            rel="noopener noreferrer"
                                        >
                                            <img src={socialLink?.Image?.data?.attributes?.url} alt={socialLink?.Name} className={styles.socialIcons} />
                                        </a>
                                    ))} */}
                                    <div className={styles.socialLink}>
                                        <FacebookShareButton url={shareUrl} >
                                            <img src={Facebook} alt='Facebook' className={styles.socialIcons} />
                                        </FacebookShareButton>
                                    </div>

                                    <div className={styles.socialLink}>
                                        <TwitterShareButton url={shareUrl} title={language === 'hindi' ?
                                            ogLanguages?.ogDescription?.hindiText
                                            : ogLanguages?.ogDescription?.defaultText}>
                                            <img src={Twitter} alt='Twitter' className={styles.socialIcons} />
                                        </TwitterShareButton>
                                    </div>

                                    <div className={styles.socialLink}>
                                        <WhatsappShareButton url={shareUrl} separator=' - ' title={language === 'hindi' ?
                                            ogLanguages?.ogDescription?.hindiText
                                            : ogLanguages?.ogDescription?.defaultText}>
                                            <img src={WhatsApp} alt='Whatsapp' className={styles.socialIcons} />
                                        </WhatsappShareButton>
                                    </div>
                                </div>
                                {thankyouData?.attributes?.OTT_Link_Hindi && thankyouData?.attributes?.OTT_Link_English ? (
                                    <div className={styles.bannerAnchor}>
                                        <a href={language === 'hindi' ? thankyouData?.attributes?.OTT_Link_Hindi : thankyouData?.attributes?.OTT_Link_English} target='_blank' rel="noopener noreferrer">
                                            <img src={language === 'hindi' ? thankyouData?.attributes?.OTT_Banner_Hindi?.data?.attributes?.url : thankyouData?.attributes?.OTT_Banner_English?.data?.attributes?.url} alt='OTT Play' className={styles.bannerImg} loading="eager" />
                                        </a>
                                    </div>
                                ) :
                                    null}

                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        setQuizAnswered(false);
                                        closeSubmitPopUp();
                                        localStorage.clear();
                                        setPageType('select-city');
                                    }}
                                >
                                    <span>{
                                        language === 'hindi' ?
                                            popupButtonLanguages?.button?.hindiText
                                            : popupButtonLanguages?.button?.defaultText
                                    }
                                    </span>
                                </button>
                            </div>
                        </React.Fragment>
                    )}
                </div>
            </div>
        </div>
    );
};

SubmitPopUp.propTypes = {
    closeSubmitPopUp: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    setQuizAnswered: PropTypes.func.isRequired,
    setPageType: PropTypes.func.isRequired,
    loaderColor: PropTypes.string.isRequired,
};

export default SubmitPopUp;