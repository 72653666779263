import React from 'react';
import PropTypes from 'prop-types';
import ErrorIcon from '../../assets/icons/error.svg';
import { errorLanguages } from '../../helper/LanguageConstants';

import styles from './Error.module.css';

const Error = ({ language }) => {
    return (
        <React.Fragment>
            <div className={styles.errorIcon}>
                <img src={ErrorIcon} alt='error' />
            </div>
            <div className={styles.errorMessage}>
                {language === 'hindi' ?
                    errorLanguages?.errorText?.hindiText
                    : errorLanguages?.errorText?.defaultText
                }
            </div>
        </React.Fragment>
    );
};

Error.propTypes = {
    language: PropTypes.string.isRequired,
};

export default Error;