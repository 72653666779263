import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './ContactPopUp.module.css';
import ContactUs from '../ContactUs';
import { popupButtonLanguages } from '../../helper/LanguageConstants';

const ContactPopUp = ({ closeContactPopUp, language }) => {
    return (
        <div
            className={styles.contactOverlay}
            onClick={closeContactPopUp}
        >
            <div className={styles.popupContainer}>
                <ContactUs language={language} />
                <button className={styles.button} onClick={closeContactPopUp}>
                    <span>{
                        language === 'hindi' ?
                            popupButtonLanguages?.button?.hindiText
                            : popupButtonLanguages?.button?.defaultText
                    }
                    </span>
                </button>
            </div>
        </div>
    );
};

ContactPopUp.propTypes = {
    closeContactPopUp: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
};

export default ContactPopUp;