import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './Quiz.module.css';
import { ApiEndpoint, EditionConstants } from '../../config';
import ErrorIcon from '../../assets/icons/error.svg';
import GridLoader from 'react-spinners/GridLoader';
import Error from '../Error';

// Language Selection
import { quizPageLanguages, errorLanguages } from '../../helper/LanguageConstants';


const Quiz = ({
    setPageType,
    language,
    setQuizAnswered,
    pageType,
    contestEdition,
    loaderColor,
    cityObject,
    answers,
    setAnswers,
    setQuizId,
}) => {
    const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
    const [quizData, setQuizData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const localAnswers = JSON.parse(localStorage.getItem('answers'));

    useEffect(() => {
        if (localAnswers) {
            setAnswers(localAnswers);
        } else if (quizData?.attributes?.Question_Answers?.length) {
            const initialAnswers = quizData?.attributes?.Question_Answers.map((question) => ({
                Question: question.Question,
                Answers: question.Answers.map(() => ({ Answer: '' })),
            }));
            setAnswers(initialAnswers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quizData]);

    // Function to handle input change for an answer
    const handleAnswerChange = (questionIndex, answerIndex, value) => {
        const updatedQuestionsAndAnswers = [...answers];
        updatedQuestionsAndAnswers[questionIndex].Answers[answerIndex].Answer = value;
        setAnswers(updatedQuestionsAndAnswers);
    };

    // Function to check if all answers have been filled
    useEffect(() => {
        const allAnswersFilled = answers?.every((question) =>
            question?.Answers?.every((answer) => answer.Answer !== ''),
        );
        setIsSubmitEnabled(allAnswersFilled);
    }, [answers]);

    function getCurrentDate() {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
        const day = currentDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate;
    }

    const currentDateDate = getCurrentDate();

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/quizzes?populate[0]=Editions&filters[Date][$eq]=${currentDateDate}&filters[Editions][Name]=${EditionConstants[contestEdition].name}&filters[City][Slug][$eq]=${cityObject?.cityName}&populate[1]=City&populate[2]=Question_Answers&populate[3]=Question_Answers.Answers`;
            setLoading(true);

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setQuizData(responseData?.data[0]);
                    setQuizId(responseData?.data[0]?.id);
                    setLoading(false);
                    setError(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType, currentDateDate]);

    return (
        <React.Fragment>
            {loading ? (
                <div className={styles.contentLoader}>
                    <GridLoader
                        color={loaderColor}
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>) : error ? (
                    <div className={styles.contentLoader}>
                        <Error language={language} />
                    </div>
                ) : quizData?.attributes?.Question_Answers?.length ? (
                    <div className={styles.quizContainer}>
                        <div className={styles.questionContainer}>
                            {answers?.map((question, index) => (
                                <div key={index}>
                                    <div className={styles.question}>{index + 1}. {question.Question}</div>
                                    <div className={styles.options}>
                                        {question.Answers.map((option, optionIndex) => (
                                            <div
                                                key={optionIndex}
                                                className={styles.optionSingle}
                                            >
                                                <input
                                                    type="text"
                                                    className={styles.option}
                                                    placeholder={`${language === 'hindi' ?
                                                        quizPageLanguages?.placeholderText?.hindiText
                                                        : quizPageLanguages?.placeholderText?.defaultText
                                                        } ${optionIndex + 1}`}
                                                    value={option?.Answer}
                                                    onChange={(e) =>
                                                        handleAnswerChange(index, optionIndex, e.target.value)
                                                    }
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <button
                            className={`${styles.button} ${!isSubmitEnabled ? styles.disabled : ''}`}
                            onClick={() => {
                                setQuizAnswered(true);
                                setPageType('form');
                                localStorage.setItem('answers', JSON.stringify(answers));
                            }}
                            disabled={!isSubmitEnabled}
                        >
                            <span>{
                                language === 'hindi' ?
                                    quizPageLanguages?.button?.hindiText
                                    : quizPageLanguages?.button?.defaultText
                            }
                            </span>
                        </button>
                    </div>
                ) : (
                <div className={styles.contentLoader}>
                    <div className={styles.errorIcon}>
                        <img src={ErrorIcon} alt='error' />
                    </div>
                    <div className={styles.errorMessage}>
                        {language === 'hindi' ?
                            errorLanguages?.noQuiz?.hindiText
                            : errorLanguages?.noQuiz?.defaultText
                        }
                    </div>
                    <button
                        className={styles.button}
                        onClick={() => {
                            setPageType('select-city');
                            localStorage.removeItem('answers');
                        }}
                    >
                        <span>{
                            language === 'hindi' ?
                                errorLanguages?.noQuizButton?.hindiText
                                : errorLanguages?.noQuizButton?.defaultText
                        }
                        </span>
                    </button>
                </div>
            )
            }
        </React.Fragment>
    );
};

Quiz.propTypes = {
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    setQuizAnswered: PropTypes.func.isRequired,
    pageType: PropTypes.string.isRequired,
    contestEdition: PropTypes.string.isRequired,
    loaderColor: PropTypes.string.isRequired,
    cityObject: PropTypes.object.isRequired,
    answers: PropTypes.arrayOf(PropTypes.object).isRequired,
    setAnswers: PropTypes.func.isRequired,
    setQuizId: PropTypes.func.isRequired,
};

export default Quiz;