import React from 'react';
import styles from './Dropdown.module.css';
import PropTypes from 'prop-types';

import PhoneIcon from '../../assets/icons/phone.svg';
import InfoIcon from '../../assets/icons/info-circle.svg';
import BookIcon from '../../assets/icons/book.svg';

// Language Selection
import { optionsButtonLanguages } from '../../helper/LanguageConstants';

const Dropdown = ({ closeDropdown, setPageType, language, toggleContactPopUp }) => {
    const menuOptions = [
        {
            name: language === 'hindi' ?
                optionsButtonLanguages?.contactUs?.hindiText
                : optionsButtonLanguages?.contactUs?.defaultText,
            icon: PhoneIcon,
            page: 'contact-us',
        },
        {
            name: language === 'hindi' ?
                optionsButtonLanguages?.faq?.hindiText
                : optionsButtonLanguages?.faq?.defaultText,
            icon: InfoIcon,
            page: 'faq',
        },
        {
            name: language === 'hindi' ?
                optionsButtonLanguages?.termsAndConditions?.hindiText
                : optionsButtonLanguages?.termsAndConditions?.defaultText,
            icon: BookIcon,
            page: 'terms-and-conditions',
        },
    ];

    return (
        <div className={styles.overlay} onClick={closeDropdown} >
            <div className={styles.dropdownMenu}>
                <div className={styles.menu}>
                    {menuOptions.map((menu, index) => (
                        <div
                            className={styles.menuItem}
                            key={index}
                            onClick={() => {
                                if (menu.page === 'contact-us') {
                                    toggleContactPopUp();
                                } else {
                                    setPageType(menu.page);
                                }
                            }}
                        >
                            <img src={menu.icon} alt={menu.name} />
                            <span>{menu.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

Dropdown.propTypes = {
    setPageType: PropTypes.func.isRequired,
    closeDropdown: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    toggleContactPopUp: PropTypes.func.isRequired,
};

export default Dropdown;