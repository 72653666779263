import React, { useEffect, useState } from 'react';
import styles from './ContactUs.module.css';
import MaskOne from '../../assets/banner/vector-one.svg';
import MaskTwo from '../../assets/banner/vector-two.svg';
import MaskOneRed from '../../assets/banner/vector-one-red.svg';
import MaskTwoRed from '../../assets/banner/vector-two-red.svg';
import Mail from '../../assets/icons/mail.svg';
import PropTypes from 'prop-types';

// Language Selection
import { faqPageLanguages } from '../../helper/LanguageConstants';

const ContactUs = ({ language }) => {
    const [maskOne, setMaskOne] = useState(null);
    const [maskTwo, setMaskTwo] = useState(null);

    useEffect(() => {
        if (language === 'hindi') {
            setMaskOne(MaskOneRed);
            setMaskTwo(MaskTwoRed);
        } else {
            setMaskOne(MaskOne);
            setMaskTwo(MaskTwo);
        }
    }, [language]);

    return (
        <div className={styles.backgroundContainer}>
            <img className={styles.bgMaskOne} src={maskOne} alt='Mask One' />
            <img className={styles.bgMaskTwo} src={maskTwo} alt='Mask Two' />
            <div className={styles.message}>
                <p>{language === 'hindi' ?
                    faqPageLanguages?.contactUsTitle?.hindiText
                    : faqPageLanguages?.contactUsTitle?.defaultText
                }
                </p>
            </div>
            <div className={styles.mail}>
                <img src={Mail} alt='mail' />
                <a href='mailto:support@festivalofgifts.com'>support@festivalofgifts.com</a>
            </div>
            <div>

            </div>
        </div>
    );
};

ContactUs.propTypes = {
    language: PropTypes.string.isRequired,
};

export default ContactUs;