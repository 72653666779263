import React, { useState, useRef, useEffect, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FaqPage.module.css';
import { ApiEndpoint, EditionConstants } from '../../config';
import GridLoader from 'react-spinners/GridLoader';
import Error from '../Error';

// components
import ContactUs from '../ContactUs';
import Accordion from '../Accordion';

// Language Selection
import { faqPageLanguages } from '../../helper/LanguageConstants';

const FaqPage = ({
    setPageType,
    language,
    quizAnswered,
    pageType,
    contestEdition,
    loaderColor,
}) => {
    const [activeTab, setActiveTab] = useState('');

    const [categoryOptions, setCategoryOptions] = useState([]);
    const [faqData, setFaqData] = useState([]);
    const [loadingCategories, setLoadingCategories] = useState(true);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [errorData, setErrorData] = useState(false);

    const scrollContainerRef = useRef(null);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/faq-categories?populate=*&filters[Editions][Name]=${EditionConstants[contestEdition].name}`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setCategoryOptions(responseData?.data);
                    setLoadingCategories(false);
                    setError(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoadingCategories(false);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType]);

    useEffect(() => {
        if (categoryOptions?.length) {
            setActiveTab(categoryOptions[0]?.attributes?.Category);
        }
    }, [categoryOptions]);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/faqs?populate[0]=Editions&filters[Faq_Categories][Category]=${activeTab}&populate[1]=Faq_Categories`;
            setLoading(true);

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setFaqData(responseData?.data);
                    setLoading(false);
                    setErrorData(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setErrorData(true);
                });
        }
        if (activeTab !== '') {
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);

    useLayoutEffect(() => {
        if (scrollContainerRef.current && activeTab !== undefined) {
            const activeIndex = categoryOptions?.findIndex(item => item?.attributes?.Category === activeTab);

            if (activeIndex !== -1) {
                const activeElement = scrollContainerRef.current.children[activeIndex];
                if (activeElement) {
                    const scrollPosition =
                        activeElement.offsetLeft -
                        scrollContainerRef.current.clientWidth / 2 +
                        activeElement.clientWidth / 2;

                    scrollContainerRef.current.scrollLeft = scrollPosition;
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeTab]);


    return (
        <React.Fragment>
            {loadingCategories ? (
                <div className={styles.contentLoader}>
                    <GridLoader
                        color={loaderColor}
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>) : error ? (
                    <div className={styles.contentLoader}>
                        <Error language={language} />
                    </div>
                ) : (
                <div className={styles.faqContainer}>
                    <ContactUs language={language} />
                    {/* <div className={styles.tabs} ref={scrollContainerRef}>
                        {categoryOptions?.map((tab, index) => (
                            <div
                                key={index}
                                className={`${styles.tabItem} ${activeTab === tab?.attributes?.Category ? styles.active : ''}`}
                                onClick={() => setActiveTab(tab?.attributes?.Category)}>
                                <span>
                                    {tab?.attributes?.Category}
                                </span>
                            </div>
                        ))}
                    </div> */}
                    <div className={styles.accordionContainer}>
                        {loading ? (
                            <div className={styles.contentLoader}>
                                <GridLoader
                                    color={loaderColor}
                                    loading={true}
                                    size={20}
                                />
                            </div>) : errorData ? (
                                <div className={styles.contentLoader}>
                                    <Error language={language} />
                                </div>
                            ) : (
                            <Accordion accordionData={faqData} language={language} />
                        )}
                    </div>
                    <button className={styles.button} onClick={() => {
                        if (quizAnswered) {
                            setPageType('form');
                        } else {
                            setPageType('select-city');
                        }
                    }}>
                        <span> {'<'} &nbsp; {
                            language === 'hindi' ?
                                faqPageLanguages?.button?.hindiText
                                : faqPageLanguages?.button?.defaultText
                        }
                        </span>
                    </button>
                </div>
            )}
        </React.Fragment>
    );
};

FaqPage.propTypes = {
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    quizAnswered: PropTypes.bool.isRequired,
    pageType: PropTypes.string.isRequired,
    contestEdition: PropTypes.string.isRequired,
    loaderColor: PropTypes.string.isRequired,
};

export default FaqPage;