import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiEndpoint, EditionConstants } from '../../config';
import GridLoader from 'react-spinners/GridLoader';
import Error from '../Error';
import ReactMarkdown from 'react-markdown';

// Styles
import styles from './TermsAndConditions.module.css';

// Language Selection
import { termsConditionsLanguages } from '../../helper/LanguageConstants';

const TermsAndConditions = ({
    setPageType,
    language,
    quizAnswered,
    pageType,
    contestEdition,
    loaderColor,
}) => {

    const [termsAndConditions, setTermsAndConditions] = useState('');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/tncs?filters[Editions][Name]=${EditionConstants[contestEdition].name}`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setTermsAndConditions(responseData?.data[0]);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType]);

    return (
        <React.Fragment>
            {loading ? (
                <div className={styles.contentLoader}>
                    <GridLoader
                        color={loaderColor}
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>) : error ? (
                    <div className={styles.contentLoader}>
                        <Error language={language} />
                    </div>
                ) : (
                <div className={styles.termsContainer}>
                    <div className={styles.contentContainer}>
                        <h3>{
                            language === 'hindi' ?
                                termsConditionsLanguages?.title?.hindiText
                                : termsConditionsLanguages?.title?.defaultText
                        }</h3>
                        <ReactMarkdown className={styles.htmlContent}>{termsAndConditions?.attributes?.TNC}</ReactMarkdown>
                    </div>
                    <button className={styles.button} onClick={() => {
                        if (quizAnswered) {
                            setPageType('form');
                        } else {
                            setPageType('select-city');
                        }
                    }}>
                        <span> {'<'} &nbsp; {
                            language === 'hindi' ?
                                termsConditionsLanguages?.button?.hindiText
                                : termsConditionsLanguages?.button?.defaultText
                        }
                        </span>
                    </button>
                </div>
            )}
        </React.Fragment>

    );
};

TermsAndConditions.propTypes = {
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    quizAnswered: PropTypes.bool.isRequired,
    pageType: PropTypes.string.isRequired,
    contestEdition: PropTypes.string.isRequired,
    loaderColor: PropTypes.string.isRequired,
};

export default TermsAndConditions;