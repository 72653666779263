import React, { useState, useEffect } from 'react';
import styles from './Winners.module.css';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import WinnersMask from '../../assets/banner/winner-mask.svg';
import CelebrationMaskOne from '../../assets/banner/winners-frame-1.svg';
import CelebrationMaskTwo from '../../assets/banner/winners-frame-2.svg';
import { ApiEndpoint, EditionConstants } from '../../config';
import { GridLoader } from 'react-spinners';
import Error from '../Error';

import 'react-datepicker/dist/react-datepicker.css';
import './Misc.css';

import Calender from '../../assets/icons/calendar.svg';

import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ErrorIcon from '../../assets/icons/error.svg';


// Language Selection
import { winnersPageLanguages, errorLanguages } from '../../helper/LanguageConstants';

const Winners = ({
    language,
    setPageType,
    pageType,
    contestEdition,
    loaderColor,
}) => {
    const [startDate, setStartDate] = useState('');
    const [date, setDate] = useState('');

    const [minimumDate, setMinimumDate] = useState('');
    const [dateChanged, setDateChanged] = useState(true);

    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [winnersData, setWinnersData] = useState('');
    const [raffleWinners, setRaffleWinners] = useState([]);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/winners?${EditionConstants[contestEdition].name === 'Hindustan Times' || EditionConstants[contestEdition].name === 'Hindustan City' ? 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan Times&filters[$or][1][Quiz_Submission][Quiz][Editions][Name]=Hindustan City' : 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan'}&populate[0]=Quiz_Submission&populate[1]=Quiz_Submission.Quiz&populate[2]=Quiz_Submission.Quiz.Editions&populate[3]=Quiz_Submission.City&populate[4]=Raffle&populate[5]=Raffle.Image&sort=Quiz_Submission.Quiz.Date:desc`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    if (responseData?.data?.length) {
                        handleDateChange(new Date(responseData?.data[0]?.attributes?.Quiz_Submission?.data?.attributes?.Quiz?.data?.attributes?.Date));
                        if (dateChanged) {
                            setMinimumDate(new Date(responseData?.data[0]?.attributes?.Quiz_Submission?.data?.attributes?.Quiz?.data?.attributes?.Date));
                            setDateChanged(false);
                        }
                    } else {
                        handleDateChange(new Date());
                    }
                    setError(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType]);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/winners?${EditionConstants[contestEdition].name === 'Hindustan Times' || EditionConstants[contestEdition].name === 'Hindustan City' ? 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan Times&filters[$or][1][Quiz_Submission][Quiz][Editions][Name]=Hindustan City' : 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan'}${date && `&filters[Quiz_Submission][Quiz][Date]=${date}`}&populate[0]=Quiz_Submission&populate[1]=Quiz_Submission.Quiz&populate[2]=Quiz_Submission.Quiz.Editions&populate[3]=Quiz_Submission.City&populate[4]=Raffle&populate[5]=Raffle.Image&sort=Quiz_Submission.Quiz.Date:desc`;
            setLoading(true);

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    if (date !== '') {
                        setWinnersData(responseData?.data);
                        setLoading(false);
                    }
                    setError(false);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(true);
                });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date]);

    useEffect(() => {
        if (winnersData?.length) {
            setRaffleWinners(
                winnersData?.filter(item => item?.attributes?.Raffle !== null)
                    .map(item => ({
                        text: item?.attributes?.Raffle?.Text,
                        location: item?.attributes?.Raffle?.Location,
                        imgUrl: item?.attributes?.Raffle?.Image?.data?.attributes?.url,
                        edition: item?.attributes?.Quiz_Submission?.data?.attributes?.Quiz?.data?.attributes?.Editions?.data[0]?.attributes?.Name,
                    })));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [winnersData]);

    const handleDateChange = (dateValue) => {
        setStartDate(dateValue);
        const selectedDate = dateValue;
        const year = selectedDate.getFullYear();
        const month = (selectedDate.getMonth() + 1).toString().padStart(2, '0');
        const day = selectedDate.getDate().toString().padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        setDate(formattedDate);
    };

    return (
        <div className={styles.winnersContainer}>
            <div className={styles.dateContainer}>
                <DatePicker
                    selected={startDate}
                    onChange={(date) => handleDateChange(date)}
                    dateFormat="dd/MM/yyyy"
                    placeholderText='Select Date'
                    className={styles.dateInput}
                    maxDate={minimumDate}
                    minDate={new Date('2023-10-12')}
                />
                <img src={Calender} alt='calender' className={styles.calender} />
            </div>
            <div className={styles.contentContainer}>
                <h3>
                    {
                        language === 'hindi' ?
                            winnersPageLanguages?.title?.hindiText
                            : winnersPageLanguages?.title?.defaultText
                    }
                </h3>
                {loading ? (
                    <div className={styles.contentLoader}>
                        <GridLoader
                            color={loaderColor}
                            loading={true}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>) : error ? (
                        <div className={styles.contentLoader}>
                            <Error language={language} />
                        </div>
                    ) : winnersData?.length ? (
                        <React.Fragment>
                            <div className={styles.winnersInnerContainer}>
                                {winnersData?.map((winner, index) => (
                                    <div key={index} className={styles.winnerItem}>
                                        <img src={WinnersMask} alt='winners mask' className={styles.winnersMask} />
                                        <img src={CelebrationMaskOne} alt='Celebration Mask One' className={styles.celMaskOne} />
                                        <img src={CelebrationMaskTwo} alt='Celebration Mask Two' className={styles.celMaskTwo} />
                                        <div className={styles.winnerInfo}>
                                            <span className={styles.winnerTitle}>{winner?.attributes?.Quiz_Submission?.data?.attributes?.Full_Name}</span>
                                            <span className={styles.winnerCity}>{winner?.attributes?.Quiz_Submission?.data?.attributes?.City?.data?.attributes?.Name} {'('}{winner?.attributes?.Quiz_Submission?.data?.attributes?.Quiz?.data?.attributes?.Editions?.data?.attributes?.Name}{')'}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className={styles.slider}>
                                <Slider {...settings}>
                                    {raffleWinners?.map((winner, index) => (
                                        <div key={index}>
                                            <div className={styles.sliderItem}>
                                                <img src={winner?.imgUrl} alt='winners img' className={styles.sliderImg} />
                                                <div className={styles.sliderInfo}>
                                                    <span>{winner?.text}</span>
                                                    &nbsp;
                                                    <span>{winner?.location}</span>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </React.Fragment>
                    ) : (
                    <div className={styles.contentLoader}>
                        <div className={styles.errorIcon}>
                            <img src={ErrorIcon} alt='error' />
                        </div>
                        <div className={styles.errorMessage}>
                            {language === 'hindi' ?
                                errorLanguages?.noWinners?.hindiText
                                : errorLanguages?.noWinners?.defaultText
                            }
                        </div>
                    </div>
                )}
            </div>
            <button className={styles.button} onClick={() => setPageType('select-city')}>
                <span> {'<'} &nbsp; {
                    language === 'hindi' ?
                        winnersPageLanguages?.button?.hindiText
                        : winnersPageLanguages?.button?.defaultText
                }
                </span>
            </button>
        </div>
    );
};

Winners.propTypes = {
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    pageType: PropTypes.string.isRequired,
    contestEdition: PropTypes.string.isRequired,
    loaderColor: PropTypes.string.isRequired,
};

export default Winners;