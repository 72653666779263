import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

// Components
import ThemeSelector from './components/ThemeSelector';
import Main from './pages/Main';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/hindustantimes" element={
          <ThemeSelector themeColor="--primary-color-blue" color="--primary-blue-rgb" font="--english-font-family" fontSize="--font-size">
            <Main />
          </ThemeSelector>
        }>
        </Route>
        <Route path="/htcity" element={
          <ThemeSelector themeColor="--primary-color-blue" color="--primary-blue-rgb" font="--english-font-family" fontSize="--font-size">
            <Main />
          </ThemeSelector>
        }>
        </Route>
        <Route path="/hindustan" element={
          <ThemeSelector themeColor="--primary-color-red" color="--primary-red-rgb" font="--hindi-font-family" fontSize="--font-size-hindi">
            <Main />
          </ThemeSelector>
        }>
        </Route>
        <Route path="*" element={<Navigate to="/hindustantimes" />} />
      </Routes>
    </Router>
  );
}

export default App;
