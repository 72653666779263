import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LocationPin from '../../assets/icons/location-pin.svg';
import GridLoader from 'react-spinners/GridLoader';
import Error from '../Error';
import { useLocation } from 'react-router-dom';

// Language Selector
import { cityPageLanguage, quizClosedLanguages } from '../../helper/LanguageConstants';

import styles from './CitySelection.module.css';

import { ApiEndpoint, EditionConstants } from '../../config';

const CitySelection = ({
    bannerImg,
    setPageType,
    language,
    contestEdition,
    pageType,
    loaderColor,
    setCityObject,
}) => {

    const [cityData, setCityData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [winnerData, setWinnersData] = useState([]);
    const { search } = useLocation();
    const currentTime = new Date();
    const currentHour = currentTime.getHours();

    const searchParams = new URLSearchParams(search);

    // Check if the current time is between 12 AM and 6 AM
    const isNotAvailable = currentHour >= 0 && currentHour < 6;

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/cities?populate=*&filters[Editions][Name][$eq]=${EditionConstants[contestEdition].name}&sort=updatedAt:desc`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setCityData(responseData);
                    setLoading(false);
                    setWinnersData([]);
                })
                .catch((error) => {
                    console.error('Error:', error);
                    setLoading(false);
                    setError(true);
                });
        }
        async function fetchWinnerData() {
            const apiUrl = `${ApiEndpoint}/winners?${EditionConstants[contestEdition].name === 'Hindustan Times' || EditionConstants[contestEdition].name === 'Hindustan City' ? 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan Times&filters[$or][1][Quiz_Submission][Quiz][Editions][Name]=Hindustan City' : 'filters[$or][0][Quiz_Submission][Quiz][Editions][Name]=Hindustan'}&populate[0]=Quiz_Submission&populate[1]=Quiz_Submission.Quiz&populate[2]=Quiz_Submission.Quiz.Editions&populate[3]=Quiz_Submission.City&populate[4]=Raffle&populate[5]=Raffle.Image&sort=Quiz_Submission.Quiz.Date:desc`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setWinnersData(responseData?.data);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        fetchWinnerData();
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageType]);

    return (
        <React.Fragment>
            {loading ? (
                <div className={styles.contentLoader}>
                    <GridLoader
                        color={loaderColor}
                        loading={true}
                        size={25}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>) : error ? (
                    <div className={styles.contentLoader}>
                        <Error language={language} />
                    </div>
                ) : (
                <div className={styles.innerContent}>

                    <div className={styles.floatingContainer}>
                        <img src={bannerImg} alt='Gold Harvest' className={styles.bannerImg} loading="eager" />
                        {isNotAvailable && searchParams.get('type') !== 'ethinos-testing' ? (
                            <React.Fragment>
                                {/* <div className={styles.contentLoader}> */}
                                <div className={styles.errorMessage}>
                                    {language === 'hindi' ?
                                        quizClosedLanguages?.title?.hindiText
                                        : quizClosedLanguages?.title?.defaultText
                                    }
                                </div>
                                {/* </div> */}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                {contestEdition === 'htcity' ? (
                                    <React.Fragment>
                                        <h3 className={styles.headLine} >
                                            Start quiz by clicking below
                                        </h3>
                                        <button
                                            className={styles.attemptButton}
                                            onClick={() => {
                                                setCityObject({
                                                    cityName: cityData?.data[0]?.attributes?.Slug,
                                                    cityId: cityData?.data[0]?.id,
                                                });
                                                setPageType('quiz');
                                            }}
                                        >
                                            <span>
                                                {language === 'hindi' ?
                                                    cityPageLanguage?.quizButton?.hindiText
                                                    : cityPageLanguage?.quizButton?.defaultText
                                                }
                                            </span>
                                        </button>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <h3 className={styles.headLine} >
                                            {language === 'hindi' ?
                                                cityPageLanguage?.selectCityTitle?.hindiText
                                                : cityPageLanguage?.selectCityTitle?.defaultText
                                            }
                                        </h3>
                                        <div className={`${styles.cityContainer} ${cityData?.data?.length > 4 ? styles.twoPerRow : ''}`} >
                                            {cityData?.data?.map((city, index) => (
                                                <div className={`${styles.outerMap} ${cityData?.data?.length <= 4 ? styles.fullWidth : ''}`} key={index}>
                                                    <div
                                                        className={styles.city}
                                                        onClick={() => {
                                                            setCityObject({
                                                                cityName: city?.attributes?.Slug,
                                                                cityId: city?.id,
                                                            });
                                                            setPageType('quiz');
                                                        }}
                                                    >
                                                        <img src={LocationPin} alt='Location' />
                                                        <span>{city?.attributes?.Name}</span>
                                                    </div>
                                                    {cityData?.data?.length > 4 ?
                                                        cityData?.data?.length - 2 > index && (<div className={styles.divider} />)
                                                        : cityData?.data?.length - 1 !== index && (<div className={styles.divider} />)
                                                    }
                                                </div>
                                            ))}
                                        </div>
                                    </React.Fragment>
                                )
                                }
                            </React.Fragment>
                        )}
                    </div>
                    {winnerData?.length ? (
                        <button className={styles.button} onClick={() => setPageType('winners')}>
                            <span>
                                {language === 'hindi' ?
                                    cityPageLanguage?.button?.hindiText
                                    : cityPageLanguage?.button?.defaultText
                                }
                            </span>
                        </button>
                    ) : null}
                </div>
            )}
        </React.Fragment>
    );
};

CitySelection.propTypes = {
    bannerImg: PropTypes.string,
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    pageType: PropTypes.string.isRequired,
    contestEdition: PropTypes.string.isRequired,
    loaderColor: PropTypes.string.isRequired,
    setCityObject: PropTypes.func.isRequired,
};


export default CitySelection;