import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiEndpoint, EditionConstants } from '../../config';
import ClipLoader from 'react-spinners/ClipLoader';

// Styles
import styles from './Form.module.css';

// Language Selection
import { formPageLanguages, inputErrorLanguages } from '../../helper/LanguageConstants';

const Form = ({
    language,
    toggleSubmitPopUp,
    setPageType,
    answers,
    cityObject,
    quizId,
    contestEdition,
    toggleQuizExpired,
}) => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    const [name, setName] = useState(userData?.name ? userData.name : '');
    const [phone, setPhone] = useState(userData?.phone ? userData.phone : '');
    const [email, setEmail] = useState(userData?.email ? userData.email : '');
    const [age, setAge] = useState(userData?.age ? userData.age : '');
    const [gender, setGender] = useState(userData?.gender ? userData.gender : '');
    const [concent, setConcent] = useState(true);
    const currentDateISOString = new Date().toISOString();
    const [error, setError] = useState({
        name: '',
        phone: '',
        email: '',
        age: '',
        gender: '',
    });

    const ageOptions = [
        { value: '15-20', label: '15-20' },
        { value: '21-25', label: '21-25' },
        { value: '26-30', label: '26-30' },
        { value: '31-35', label: '31-35' },
        { value: '36-40', label: '36-40' },
        { value: '41-45', label: '41-45' },
        { value: '46-50', label: '46-50' },
        { value: '51-55', label: '51-55' },
        { value: '56-60', label: '56-60' },
        { value: '61-65', label: '61-65' },
        { value: '66-70', label: '66-70' },
        { value: '71-75', label: '71-75' },
        { value: '75+', label: '75+' },
    ];

    const genderOptions = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ];

    const [loading, setLoading] = useState(false);
    const [apiError, setApiError] = useState(false);

    const handleError = (state) => {
        let fields = state;
        let errors = {};
        let formIsValid = true;

        if (!fields['Full_Name']) {
            formIsValid = false;
            errors['name'] = language === 'hindi' ?
                inputErrorLanguages?.nameEmpty?.hindiText
                : inputErrorLanguages?.nameEmpty?.defaultText;
        }
        if (typeof fields['Full_Name'] !== 'undefined') {
            if (!fields['Full_Name'].match(/^[a-zA-Z ]*$/)) {
                formIsValid = false;
                errors['name'] = language === 'hindi' ?
                    inputErrorLanguages?.nameError?.hindiText
                    : inputErrorLanguages?.nameError?.defaultText;
            }
        }

        if (fields['Email']) {
            var pattern = new RegExp(
                /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(fields['Email'])) {
                formIsValid = false;
                errors['email'] = language === 'hindi' ?
                    inputErrorLanguages?.emailError?.hindiText
                    : inputErrorLanguages?.emailError?.defaultText;
            }
        }
        if (!fields['Mobile_Number']) {
            formIsValid = false;
            errors['phone'] = language === 'hindi' ?
                inputErrorLanguages?.phoneEmpty?.hindiText
                : inputErrorLanguages?.phoneEmpty?.defaultText;
        }
        if (typeof fields['Mobile_Number'] !== 'undefined') {
            var patternOne = new RegExp(/^\d{10}$/);
            if (!patternOne.test(fields['Mobile_Number'])) {
                formIsValid = false;
                errors['phone'] = language === 'hindi' ?
                    inputErrorLanguages?.phoneError?.hindiText
                    : inputErrorLanguages?.phoneError?.defaultText;
            }
        }
        if (!fields['Age']) {
            formIsValid = false;
            errors['age'] = language === 'hindi' ?
                inputErrorLanguages?.ageError?.hindiText
                : inputErrorLanguages?.ageError?.defaultText;
        }
        if (!fields['Gender']) {
            formIsValid = false;
            errors['gender'] = language === 'hindi' ?
                inputErrorLanguages?.genderError?.hindiText
                : inputErrorLanguages?.genderError?.defaultText;
        }

        return { formIsValid, errors };
    };

    const handleButtonClick = () => {
        const currentTime = new Date();
        const currentHour = currentTime.getHours();

        // Check if the current time is between 12 AM and 6 AM
        const isNotAvailable = currentHour >= 0 && currentHour < 6;

        if (isNotAvailable) {
            toggleQuizExpired();
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        const data = {
            Full_Name: name,
            Mobile_Number: phone,
            Tnc_Consent: concent,
            Quiz: quizId,
            Submission_Date: currentDateISOString,
            City: cityObject?.cityId,
            Edition: EditionConstants[contestEdition].id,
            Submitted_Question_Answer: answers,
            Age: age,
            Gender: gender,
        };

        if (email) {
            data.Email = email;
        }

        const { formIsValid, errors } = handleError(data);
        setError(errors);

        if (formIsValid) {
            try {
                const url = `${ApiEndpoint}/quiz-submissions`;
                setLoading(true);

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ data }),
                });

                if (!response.ok) {
                    setLoading(false);
                    throw new Error('Network response was not ok');
                }
                setLoading(false);
                setApiError(false);
                localStorage.clear();
                toggleSubmitPopUp();
            } catch (error) {
                setLoading(false);
                setApiError(true);
                console.error('Error submitting data:', error);
            }
        }
    };

    useEffect(() => {
        setError((prevError) => ({
            ...prevError,
            name: name ? '' : prevError.name,
            phone: phone ? '' : prevError.phone,
            email: email ? '' : prevError.email,
            age: age ? '' : prevError.age,
            gender: gender ? '' : prevError.gender,
        }));
    }, [name, phone, email, age, gender]);

    return (
        <div className={styles.formContainer}>
            <h3 className={styles.headLine}>
                {language === 'hindi' ?
                    formPageLanguages?.title?.hindiText
                    : formPageLanguages?.title?.defaultText
                }
            </h3>
            <div className={styles.formContent}>
                <form className={styles.form}>
                    <div className={styles.inputContainer}>
                        <input
                            type="text"
                            className={styles.input}
                            placeholder={`${language === 'hindi' ?
                                formPageLanguages?.namePlaceHolder?.hindiText
                                : formPageLanguages?.namePlaceHolder?.defaultText
                                }*`}
                            value={name}
                            onChange={(e) =>
                                setName(e.target.value)
                            }
                            style={error.name ? { border: '1px solid red' } : {}}
                        />
                        {error.name && <div className={styles.inputError}>
                            <span>{error.name}</span>
                        </div>
                        }
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            type="number"
                            className={styles.input}
                            placeholder={`${language === 'hindi' ?
                                formPageLanguages?.phonePlaceHolder?.hindiText
                                : formPageLanguages?.phonePlaceHolder?.defaultText
                                }*`}
                            value={phone}
                            onChange={(e) =>
                                setPhone(e.target.value)
                            }
                            style={error.phone ? { border: '1px solid red' } : {}}
                        />
                        {error.phone && <div className={styles.inputError}>
                            <span>{error.phone}</span>
                        </div>
                        }
                    </div>
                    <div className={styles.inputContainer}>
                        <input
                            type="text"
                            className={styles.input}
                            placeholder={language === 'hindi' ?
                                formPageLanguages?.emailPlaceHolder?.hindiText
                                : formPageLanguages?.emailPlaceHolder?.defaultText
                            }
                            value={email}
                            onChange={(e) =>
                                setEmail(e.target.value)
                            }
                            style={error.email ? { border: '1px solid red' } : {}}
                        />
                        {error.email && <div className={styles.inputError}>
                            <span>{error.email}</span>
                        </div>
                        }
                    </div>
                    <div className={styles.selectFormContainer}>
                        <div className={styles.innerSelectContainers}>
                            <div className={styles.selectContainer}>
                                <span className={styles.arrow}>&#9660;</span>
                                <select
                                    name="age"
                                    id="age"
                                    className={styles.selectInput}
                                    value={age}
                                    onChange={(e) => setAge(e.target.value)}
                                    style={error.age ? { border: '1px solid red' } : {}}
                                >
                                    <option value="" disabled>{language === 'hindi' ?
                                        formPageLanguages?.age?.hindiText
                                        : formPageLanguages?.age?.defaultText}</option>
                                    {ageOptions?.map((e, index) => (
                                        <option key={index} value={e.value}>
                                            {e.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {error.age && <div className={styles.inputError}>
                                <span>{error.age}</span>
                            </div>
                            }
                        </div>
                        <div className={styles.innerSelectContainers}>
                            <div className={styles.selectContainer}>
                                <span className={styles.arrow}>&#9660;</span>
                                <select
                                    name="gender"
                                    id="gender"
                                    className={styles.selectInput}
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    style={error.gender ? { border: '1px solid red' } : {}}
                                >
                                    <option value="" disabled>{language === 'hindi' ?
                                        formPageLanguages?.gender?.hindiText
                                        : formPageLanguages?.gender?.defaultText}</option>
                                    {genderOptions?.map((e, index) => (
                                        <option key={index} value={e.value}>
                                            {e.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            {error.gender && <div className={styles.inputError}>
                                <span>{error.gender}</span>
                            </div>
                            }
                        </div>
                    </div>
                    <div className={styles.checkbox}>
                        <input
                            type="checkbox"
                            id="concent"
                            className={styles.checkboxInput}
                            checked={concent}
                            onChange={() =>
                                setConcent(!concent)
                            }
                        />
                        <label className={styles.checkboxLabel} htmlFor="concent">
                            {language === 'hindi' ?
                                <span>
                                    मैं हिन्दुस्तान मीडिया वेंचर्स लिमिटेड को मार्केटिंग उद्देश्यों के लिए मुझसे संपर्क करने की अनुमति देता/देती हूं और मैं सभी <span className={styles.conditions} onClick={() => {
                                        localStorage.setItem('userData', JSON.stringify({
                                            name: name,
                                            phone: phone,
                                            email: email,
                                            age: age,
                                            gender: gender,
                                        }));
                                        setPageType('terms-and-conditions');
                                    }}>
                                        नियम व शर्तें
                                    </span> और <a className={styles.conditions} href='https://www.livehindustan.com/subscription-privacy-policy/' target='_blank' rel="noopener noreferrer">प्राइवेसी पॉलिसी</a> से सहमत हूं
                                </span>
                                :
                                <span>
                                    I authorise, HT Media Limited to contact me for marketing purposes, and I agree to all the <span className={styles.conditions} onClick={() => {
                                        localStorage.setItem('userData', JSON.stringify({
                                            name: name,
                                            phone: phone,
                                            email: email,
                                            age: age,
                                            gender: gender,
                                        }));
                                        setPageType('terms-and-conditions');
                                    }}>
                                        terms and conditions
                                    </span> and <a className={styles.conditions} href='https://www.hindustantimes.com/privacy-policy' target='_blank' rel="noopener noreferrer">privacy policy</a>.
                                </span>
                            }
                        </label>
                    </div>
                </form>
            </div>
            {apiError && (
                <div className={styles.error}>
                    {
                        language === 'hindi' ?
                            formPageLanguages?.apiError?.hindiText
                            : formPageLanguages?.apiError?.defaultText
                    }
                </div>
            )}
            <button
                className={`${styles.button} ${!concent || loading ? styles.disabled : ''}`}
                onClick={handleButtonClick}
                disabled={!concent || loading}
            >
                {loading ? (
                    <span>
                        <ClipLoader
                            color='#FFFFFF'
                            loading={loading}
                            size={15}
                        />
                    </span>
                ) : (
                    <span>{
                        language === 'hindi' ?
                            formPageLanguages?.button?.hindiText
                            : formPageLanguages?.button?.defaultText
                    }
                    </span>
                )}
            </button>
        </div>
    );
};

Form.propTypes = {
    setPageType: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    toggleSubmitPopUp: PropTypes.func.isRequired,
    answers: PropTypes.arrayOf(PropTypes.object).isRequired,
    pageType: PropTypes.string.isRequired,
    cityObject: PropTypes.object.isRequired,
    quizId: PropTypes.number.isRequired,
    contestEdition: PropTypes.string.isRequired,
    toggleQuizExpired: PropTypes.func.isRequired,
};

export default Form;