import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './ThemeSelector.module.css';

const ThemeSelector = ({ themeColor, color, font, fontSize, children }) => {
    useEffect(() => {
        document.documentElement.style.setProperty('--main-theme-color', `var(${themeColor})`);
        document.documentElement.style.setProperty('--main-bg', `var(${color}`);
        document.documentElement.style.setProperty('--main-font', `var(${font}`);
        document.documentElement.style.setProperty('--main-font-size', `var(${fontSize}`);

    }, [themeColor, color, font, fontSize]);

    return (
        <div className={styles.rootContainer}>
            <div className={styles.mask} />
            {children}
        </div>
    );
};

ThemeSelector.propTypes = {
    themeColor: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    font: PropTypes.string.isRequired,
    fontSize: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
        PropTypes.object,
    ]).isRequired,
};

export default ThemeSelector;
