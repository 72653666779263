import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './Accordion.module.css';

import Plus from '../../assets/icons/plus.svg';
import Minus from '../../assets/icons/minus.svg';
import ReactMarkdown from 'react-markdown';
import ErrorIcon from '../../assets/icons/error.svg';

import { errorLanguages } from '../../helper/LanguageConstants';

const Accordion = ({ accordionData, language }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const toggleAccordion = (index) => {
        if (activeIndex === index) {
            setActiveIndex(null);
        } else {
            setActiveIndex(index);
        }
    };

    return (
        <React.Fragment>
            {accordionData?.length ? (
                accordionData?.map((question, index) => (
                    <div key={index} className={`${styles.accordionItem} ${activeIndex === index ? styles.active : ''}`}>
                        <div
                            className={`${styles.iconContainer} ${activeIndex === index ? styles.active : ''}`}
                            onClick={() => toggleAccordion(index)}
                        >
                            {activeIndex == index ? (
                                <img src={Minus} alt='minus' className={`${styles.rotateIcon} ${activeIndex === index ? styles.active : ''}`} />
                            ) : (
                                <img src={Plus} alt='plus' className={`${styles.rotateIcon} ${activeIndex === index ? styles.active : ''}`} />
                            )}
                        </div>
                        <div onClick={() => toggleAccordion(index)} className={styles.question}>
                            <ReactMarkdown>
                                {question?.attributes?.Question}
                            </ReactMarkdown>
                        </div>
                        {activeIndex === index && (
                            <ReactMarkdown
                                className={`${styles.answer} ${activeIndex === index ? styles.open : ''}`}
                            >
                                {question?.attributes?.Answer}
                            </ReactMarkdown>
                        )}
                    </div>
                ))) : (
                <div className={styles.contentLoader}>
                    <div className={styles.errorIcon}>
                        <img src={ErrorIcon} alt='error' />
                    </div>
                    <div className={styles.errorMessage}>
                        {language === 'hindi' ?
                            errorLanguages?.noFaq?.hindiText
                            : errorLanguages?.noFaq?.defaultText
                        }
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

Accordion.propTypes = {
    accordionData: PropTypes.arrayOf(PropTypes.object).isRequired,
    language: PropTypes.string.isRequired,
};

export default Accordion;