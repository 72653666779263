// Home Page Language Constants
export const cityPageLanguage = {
    button: {
        defaultText: 'List of Previous Winners',
        hindiText: 'पिछले विजेताओं की सूची',
    },
    selectCityTitle: {
        defaultText: 'Select Your City',
        hindiText: 'अपना शहर चुनें',
    },
    quizButton: {
        defaultText: 'Take Quiz',
        hindiText: 'क्विज़ लो',
    },
};

// Options Dropdown Language Constants
export const optionsButtonLanguages = {
    contactUs: {
        defaultText: 'Contact Us',
        hindiText: 'संपर्क करें',
    },
    faq: {
        defaultText: 'FAQs',
        hindiText: 'FAQs',
    },
    termsAndConditions: {
        defaultText: 'Terms & Conditions',
        hindiText: 'नियम व शर्तें',
    },
};

// FAQ and Contact Us Language Constants
export const faqPageLanguages = {
    contactUsTitle: {
        defaultText: 'Do you have any questions? Feel free to reach out to us.',
        hindiText: 'अधिक जानकारी के लिए हमसे संपर्क करें',
    },
    button: {
        defaultText: 'Back to Today’s Quiz',
        hindiText: 'आज की क्विज पर वापस जाएँ',
    },
};

// Quiz Page Language Constants
export const quizPageLanguages = {
    placeholderText: {
        defaultText: 'Your Answer',
        hindiText: 'आपका उत्तर',
    },
    button: {
        defaultText: 'Next',
        hindiText: 'नेक्स्ट',
    },
};

// Terms and Conditions Language Constants
export const termsConditionsLanguages = {
    button: {
        defaultText: 'Back to Today’s Quiz',
        hindiText: 'आज की क्विज पर वापस जाएँ',
    },
    title: {
        defaultText: 'Terms and Conditions',
        hindiText: 'नियम व शर्तें',
    },
};

// Popup button Language Constants
export const popupButtonLanguages = {
    button: {
        defaultText: 'Close',
        hindiText: 'बंद करें',
    },
};

// Form Page Language Constants
export const formPageLanguages = {
    title: {
        defaultText: 'Fill in Your Details for the Contest',
        hindiText: 'कृपया नीचे दी गई आवश्यक जानकारी भरें',
    },
    namePlaceHolder: {
        defaultText: 'Full Name',
        hindiText: 'पूरा नाम',
    },
    phonePlaceHolder: {
        defaultText: 'Phone Number',
        hindiText: 'फोन नंबर',
    },
    emailPlaceHolder: {
        defaultText: 'Email ID',
        hindiText: 'ईमेल आईडी',
    },
    concent: {
        defaultText: 'I agree to be contacted by HT Media for promotional purposes as per the ',
        hindiText: '',
    },
    button: {
        defaultText: 'Submit',
        hindiText: 'सबमिट करें',
    },
    tAndC: {
        defaultText: 'Terms & Conditions',
        hindiText: 'नियम व शर्तें',
    },
    age: {
        defaultText: 'Age',
        hindiText: 'ऐज',
    },
    gender: {
        defaultText: 'Gender',
        hindiText: 'जेंडर',
    },
    apiError: {
        defaultText: 'Something Went Wrong. Please Try Again',
        hindiText: 'कृपया पुनः प्रयास करें',
    },
};

// Input errors Language Constants
export const inputErrorLanguages = {
    nameEmpty: {
        defaultText: '*Please Enter Your Name.',
        hindiText: '*कृपया अपना नाम दर्ज करें।',
    },
    nameError: {
        defaultText: '*Please Use Alphabet Characters Only.',
        hindiText: '*कृपया केवल वर्णमाला वर्ण ही दर्ज करें।',
    },
    emailError: {
        defaultText: '*Please Enter a Valid Email Address.',
        hindiText: '*कृपया एक मान्य ईमेल पता दर्ज करें।',
    },
    phoneEmpty: {
        defaultText: '*Please Enter Your Phone Number.',
        hindiText: '*कृपया अपना फ़ोन नंबर दर्ज करें।',
    },
    phoneError: {
        defaultText: '*Please Enter a Valid Phone Number.',
        hindiText: '*कृपया एक मान्य फ़ोन नंबर दर्ज करें।',
    },
    ageError: {
        defaultText: '*Please Select Age.',
        hindiText: '*कृपया ऐज चुनें।',
    },
    genderError: {
        defaultText: '*Please Select Gender.',
        hindiText: '*कृपया जेंडर चुनें।',
    },
};

// Submit Pop Up Language Constants
export const submitLanguages = {
    title: {
        defaultText: 'Thank you for participating in the Hindustan Times Festival of Gifts.',
        hindiText: 'हिन्दुस्तान फेस्टिवल ऑफ गिफ्ट्स में भाग लेने के लिए धन्यवाद',
    },
    share: {
        defaultText: 'Share this fantastic opportunity to win exciting gifts such as smartphones, smartwatches, and Bluetooth speakers every day with your family and friends!',
        hindiText: 'हर दिन स्मार्ट फ़ोन जीतने का यह शानदार मौका अपने परिवार और दोस्तों के साथ भी साझा करें',
    },
};

// Winners Page Language Constants
export const winnersPageLanguages = {
    title: {
        defaultText: 'Winners',
        hindiText: 'विजेताओं की सूची',
    },
    button: {
        defaultText: 'Back to Today’s Quiz',
        hindiText: 'आज की क्विज पर वापस जाएँ',
    },
};

// Error Language Constants
export const errorLanguages = {
    errorText: {
        defaultText: 'Could Not Load. Please Try Again.',
        hindiText: 'लोड नहीं हो सका। कृपया पुन: प्रयास करें।',
    },
    noQuiz: {
        defaultText: 'There is no quiz available for today. Please try again at 6:00 AM.',
        hindiText: 'आज के लिए कोई क्विज उपलब्ध नहीं है. कृपया सुबह 6:00 बजे पुनः प्रयास करें।',
    },
    noQuizButton: {
        defaultText: 'Back to Previous Page',
        hindiText: 'पिछले पृष्ठ पर जाएं',
    },
    noFaq: {
        defaultText: 'FAQs Not Available for This Category. Choose a Different Category',
        hindiText: 'इस श्रेणी के लिए सामान्य प्रश्न उपलब्ध नहीं हैं। कृपया दूसरी श्रेणी चुनें।',
    },
    noWinners: {
        defaultText: 'No Winners Found for the Selected Date. Choose a Different Date',
        hindiText: 'चयनित तारीख के लिए विजेता नहीं मिले। कृपया एक अलग तारीख का चयन करें।',
    },
    quizExpired: {
        defaultText: 'Todays quiz session has concluded. Please try again at 6:00am.',
        hindiText: 'आज की क्विज समाप्त हो गया है। कृपया सुबह 6:00 बजे पुनः प्रयास करें।',
    },
};

// OG Language Constants
export const ogLanguages = {
    ogTitle: {
        defaultText: 'Join the Gold Coin Contest and Win Big!',
        hindiText: 'सोने के सिक्के जीतने का मौका! जुड़ें और जीतें!',
    },
    ogDescription: {
        defaultText: 'I just participated in HT contest which gives you a chance to win exciting prizes like smartphone, smartwatch and bluetooth speaker every single day. Follow the link, and you can win too! Participate now',
        hindiText: 'मैंने अभी हिन्दुस्तान फेस्टिवल ऑफ गिफ्ट्स में भाग लिया है, जिसमें आप हर रोज स्मार्टफोन जीत सकते हैं। आप भी यह शानदार मौका पाने के लिए लिंक पर क्लिक करें। अभी भाग लें!',
    },
};

// Quiz closed Constants
export const quizClosedLanguages = {
    title: {
        defaultText: 'The quiz will be starting at 6:00 am.',
        hindiText: 'यह क्विज सुबह 6:00 बजे से शुरू होगी।',
    },

};