import React from 'react';
import PropTypes from 'prop-types';

// Styles
import styles from './QuizExpired.module.css';
import { popupButtonLanguages, errorLanguages } from '../../helper/LanguageConstants';

import ErrorIcon from '../../assets/icons/error.svg';

const QuizExpired = ({
    closeQuizExpired,
    language,
    setPageType,
    setQuizAnswered,
}) => {

    return (
        <div
            className={styles.submitOverlay}
        >
            <div className={styles.popupContainer}>
                <div className={styles.innerContainer}>
                    <div className={styles.submitContent}>
                        <div className={styles.contentLoader}>
                            <div className={styles.errorIcon}>
                                <img src={ErrorIcon} alt='error' />
                            </div>
                            <div className={styles.errorMessage}>
                                {language === 'hindi' ?
                                    errorLanguages?.quizExpired?.hindiText
                                    : errorLanguages?.quizExpired?.defaultText
                                }
                            </div>
                        </div>
                        <button
                            className={styles.button}
                            onClick={() => {
                                setQuizAnswered(false);
                                closeQuizExpired();
                                localStorage.clear();
                                setPageType('select-city');
                            }}
                        >
                            <span>{
                                language === 'hindi' ?
                                    popupButtonLanguages?.button?.hindiText
                                    : popupButtonLanguages?.button?.defaultText
                            }
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

QuizExpired.propTypes = {
    closeQuizExpired: PropTypes.func.isRequired,
    language: PropTypes.string.isRequired,
    setQuizAnswered: PropTypes.func.isRequired,
    setPageType: PropTypes.func.isRequired,
    loaderColor: PropTypes.string.isRequired,
};

export default QuizExpired;