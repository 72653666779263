import React, { useState, useEffect } from 'react';
import HindustanTimesLogo from '../../assets/logos/ht-logo.svg';
import HindustanLogo from '../../assets/logos/hh-logo.svg';
import HindustanCityLogo from '../../assets/logos/htc-logo.png';
import MenuIcon from '../../assets/icons/dots-vertical.svg';
import BackButton from '../../assets/icons/back.svg';
import { useLocation } from 'react-router-dom';
import { ApiEndpoint, EditionConstants } from '../../config';
import { Helmet } from 'react-helmet';

//components
import CitySelection from '../../components/CitySelection';

import Dropdown from '../../components/Dropdown';
import FaqPage from '../../components/FaqPage';
import Quiz from '../../components/Quiz';
import TermsAndConditions from '../../components/TermsAndConditions';
import ContactPopUp from '../../components/ContactPopUp';
import Form from '../../components/Form';
import SubmitPopUp from '../../components/SubmitPopUp';
import Winners from '../../components/Winners';
import QuizExpired from '../../components/QuizExpired';

import styles from './Main.module.css';

const Main = () => {
    const [language, setLanguage] = useState('english');
    const [quizAnswered, setQuizAnswered] = useState(false);
    const [answers, setAnswers] = useState([]);
    const [quizId, setQuizId] = useState('');
    const [loaderColor, setLoaderColor] = useState('');

    const [bannerImg, setBannerImg] = useState('');
    const [mainLogo, setMainLogo] = useState('');
    const [pageType, setPageType] = useState('select-city');
    const { pathname } = useLocation();
    const contestEdition = pathname.split('/')[1];

    const shareUrl = window.location.href;

    const [cityObject, setCityObject] = useState({
        cityName: '',
        cityId: '',
    });

    /* ------ Options Dropdown --------- */
    const [isDropdownOpen, setDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!isDropdownOpen);
    };

    const closeDropdown = () => {
        setDropdownOpen(false);
    };
    /* ------ Options Dropdown --------- */

    /* ------ Contact Us Popup --------- */
    const [isContactPopUpOpen, setIsContactPopUpOpen] = useState(false);

    const toggleContactPopUp = () => {
        setIsContactPopUpOpen(!isContactPopUpOpen);
    };

    const closeContactPopUp = () => {
        setIsContactPopUpOpen(false);
    };
    /* ------ Contact Us Popup --------- */

    /* ------ Submit Popup --------- */
    const [isSubmitOpen, setIsSubmitOpen] = useState(false);

    const toggleSubmitPopUp = () => {
        setIsSubmitOpen(!isSubmitOpen);
    };

    const closeSubmitPopUp = () => {
        setIsSubmitOpen(false);
    };
    /* ------ Submit Popup --------- */

    /* ------ Quiz Expired Popup --------- */
    const [isQuizExpired, setIsQuizExpired] = useState(false);

    const toggleQuizExpired = () => {
        setIsQuizExpired(!isQuizExpired);
    };

    const closeQuizExpired = () => {
        setIsQuizExpired(false);
    };
    /* ------ Quiz Expired Popup --------- */

    useEffect(() => {
        window?.scrollTo(0, 0);
    }, [pageType]);

    useEffect(() => {
        async function fetchData() {
            const apiUrl = `${ApiEndpoint}/editions?filters[Name]=${EditionConstants[contestEdition].name}&populate[0]=Banner_Image`;

            await fetch(apiUrl)
                .then((response) => {
                    return response.json();
                })
                .then((responseData) => {
                    setBannerImg(responseData?.data[0].attributes.Banner_Image.data.attributes.url);
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
        }
        fetchData();
        if (contestEdition === 'hindustan') {
            setLanguage('hindi');
            setLoaderColor('#ba1222');
            setMainLogo(HindustanLogo);
        } else if (contestEdition === 'htcity') {
            setLanguage('english');
            setLoaderColor('#00b1cd');
            setMainLogo(HindustanCityLogo);
        } else {
            setLanguage('english');
            setLoaderColor('#00b1cd');
            setMainLogo(HindustanTimesLogo);
        }
    }, [contestEdition]);

    return (
        <React.Fragment>
            <Helmet>
                <title>Festival of Gifts | {language === 'hindi' ? 'हिन्दुस्तान' : EditionConstants[contestEdition].name}</title>

                <meta
                    name="description"
                    content={language === 'hindi' ? 'हिन्दुस्तान फेस्टिवल ऑफ़ गिफ्ट्स में महसूस उपहारों के साथ प्रतियोगिता करें और जीतें।' : 'Compete and win prizes with the feel of gifts in the Hindustan Times Festival of Gifts.'}
                />

                <meta property="og:type" content="website" />
                <meta
                    property="og:title"
                    content={language === 'hindi' ? 'जॉइन करें गिफ्ट्स कंटेस्ट और बड़ा जीतें!' : 'Join the Gifts Contest and Win Big!'}
                />
                <meta
                    property="og:description"
                    content={language === 'hindi' ?
                        'मैंने एक प्रतियोगिता जीती है जो सजावट के रूप में गिफ्ट्स देती है। इस लिंक का पालन करें, और आप भी जीत सकते हैं! अब अपना हिस्सा लेने के लिए भाग लें और गिफ्ट्स पा सकें!'
                        : 'I have won a contest that gives gifts as rewards. Follow the link, and you can win too! Participate now for your chance to get gifts!'
                    }
                />
                <meta
                    property="og:image"
                    content={bannerImg}
                />


                <meta name="twitter:card" content="summary_large_image" />
                <meta property="twitter:domain" content={shareUrl} />
                <meta
                    name="twitter:title"
                    content={language === 'hindi' ? 'जॉइन करें गिफ्ट्स कंटेस्ट और बड़ा जीतें!' : 'Join the Gifts Contest and Win Big!'}
                />
                <meta
                    name="twitter:description"
                    content={language === 'hindi' ?
                        'मैंने एक प्रतियोगिता जीती है जो सजावट के रूप में गिफ्ट्स देती है। इस लिंक का पालन करें, और आप भी जीत सकते हैं! अब अपना हिस्सा लेने के लिए भाग लें और गिफ्ट्स पा सकें!'
                        : 'I have won a contest that gives gifts as rewards. Follow the link, and you can win too! Participate now for your chance to get gifts!'
                    }
                />
                <meta
                    name="twitter:image"
                    content={bannerImg}
                />
            </Helmet>
            <div className={styles.container}>

                <header className={`${styles.header} ${contestEdition === 'htcity' ? styles.lessMargin : ''}`}>
                    {pageType === 'quiz' && (
                        <img className={styles.menuIcon} src={BackButton} alt='back' onClick={() => {
                            setPageType('select-city');
                            setAnswers([]);
                            localStorage.removeItem('answers');
                        }} />
                    )}
                    {pageType === 'form' && (
                        <img className={styles.menuIcon} src={BackButton} alt='back' onClick={() => {
                            setPageType('quiz');
                            setAnswers([]);
                        }} />
                    )}
                    <div className={`${styles.mainLogo} ${pageType === 'quiz' || pageType === 'form' ? styles.backButton : ''}`}>
                        <img src={mainLogo} alt='Hindustan Times' className={`${styles.mainLogoImg} ${contestEdition === 'htcity' ? styles.circular : ''}`} onClick={() => {
                            localStorage.clear();
                            setPageType('select-city');
                        }}
                        />
                    </div>
                    <img src={MenuIcon} alt='Menu' className={styles.menuIcon} onClick={toggleDropdown} />
                    {isDropdownOpen && (
                        <Dropdown
                            setPageType={setPageType}
                            closeDropdown={closeDropdown}
                            language={language}
                            toggleContactPopUp={toggleContactPopUp}
                        />)}
                    {isContactPopUpOpen && (
                        <ContactPopUp
                            closeContactPopUp={closeContactPopUp}
                            language={language}
                        />
                    )}
                    {isSubmitOpen && (
                        <SubmitPopUp
                            closeSubmitPopUp={closeSubmitPopUp}
                            language={language}
                            setPageType={setPageType}
                            setQuizAnswered={setQuizAnswered}
                            loaderColor={loaderColor}
                        />
                    )}
                    {isQuizExpired && (
                        <QuizExpired
                            closeQuizExpired={closeQuizExpired}
                            language={language}
                            setQuizAnswered={setQuizAnswered}
                            setPageType={setPageType}
                            loaderColor={loaderColor}
                        />
                    )}
                </header>
                <div className={styles.content}>
                    {pageType === 'select-city' && (
                        <CitySelection
                            bannerImg={bannerImg}
                            pageType={pageType}
                            setPageType={setPageType}
                            language={language}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                            setCityObject={setCityObject}
                        />
                    )}

                    {pageType === 'quiz' && (
                        <Quiz
                            setPageType={setPageType}
                            language={language}
                            setQuizAnswered={setQuizAnswered}
                            cityObject={cityObject}
                            pageType={pageType}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                            answers={answers}
                            setAnswers={setAnswers}
                            setQuizId={setQuizId}
                        />
                    )}
                    {pageType === 'form' && (
                        <Form
                            setPageType={setPageType}
                            language={language}
                            toggleSubmitPopUp={toggleSubmitPopUp}
                            answers={answers}
                            cityObject={cityObject}
                            pageType={pageType}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                            quizId={quizId}
                            toggleQuizExpired={toggleQuizExpired}
                        />
                    )}
                    {pageType === 'winners' && (
                        <Winners
                            setPageType={setPageType}
                            language={language}
                            pageType={pageType}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                        />
                    )}
                    {pageType === 'faq' && (
                        <FaqPage
                            setPageType={setPageType}
                            language={language}
                            quizAnswered={quizAnswered}
                            pageType={pageType}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                        />
                    )}
                    {pageType === 'terms-and-conditions' && (
                        <TermsAndConditions
                            setPageType={setPageType}
                            language={language}
                            quizAnswered={quizAnswered}
                            pageType={pageType}
                            contestEdition={contestEdition}
                            loaderColor={loaderColor}
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};

export default Main;
